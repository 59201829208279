<template>
  <div style="display: flex; flex-direction: column">
    <Status></Status>
    <div
      class="mt-15 ml-5 mr-5"
      style="
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <v-card min-width="300" elevation="5">
        <v-sheet
          @click="unlock()"
          dark
          color="green darken-2"
          class="pa-6"
          v-ripple
        >
          <v-card-title
            class="ma-6"
            style="display: flex; justify-content: center"
          >
            <v-icon style="font-size: 60px">fa-unlock-alt</v-icon>
          </v-card-title>
          <v-card-subtitle
            class="text-center text-h5 font-weight-light"
            style="color: white"
            >Touch to unlock</v-card-subtitle
          >
        </v-sheet>
      </v-card>
    </div>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
import CMD from "../lib/cmd";

export default {
  name: "Unlock",
  components: {
    Status,
  },
  mounted() {
    this.setup();
    // this.$store.connect();
  },
  data() {
    return {
      item: this.$store.device,
    };
  },
  methods: {
    async setup() {
      if (!this.$store.connected) {
        await this.$store.connect();
        await this.$store.connection.write(
          CMD.CMD_TIME_SET + new Date().getTime()
        );
      }
    },
    async unlock() {
      try {
        console.log("connecting");
        await this.$store.connect();
        console.log("writing message");
        // await this.$store.connection.write(CMD.CMD_TIME_SET + (new Date().getTime()));
        await this.$store.connection.write(CMD.CMD_SRV_OPEN);
        console.log("emit alert");
        this.$emit("alert", "device is open", "success");
      } catch (e) {
        console.log(e);
        this.$emit("alert", "error communicating with device", "error");
      }
    },
  },
};
</script>